<template lang="html">
  <div class="settings" ref="settings">
    <div class="settings-group" id="types" v-if="$route.name != 'info'">
      <div class="settings-title">types</div>
      <div
        class="settings-item"
        :class="{ active: $store.state.types.images }"
        @click="$store.state.types.images = !$store.state.types.images"
      >
        Images
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.types.video }"
        @click="$store.state.types.video = !$store.state.types.video"
      >
        Video
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.types.audio }"
        @click="$store.state.types.audio = !$store.state.types.audio"
      >
        Audio
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.types.documents }"
        @click="$store.state.types.documents = !$store.state.types.documents"
      >
        Documents
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.types.texts }"
        @click="$store.state.types.texts = !$store.state.types.texts"
      >
        Texts
      </div>
    </div>
    <div class="settings-group" id="works" v-if="$route.name != 'info'">
      <div class="settings-title">works</div>
      <div
        class="settings-item"
        :class="{ active: $store.state.categories.solo }"
        @click="$store.state.categories.solo = !$store.state.categories.solo"
      >
        Selected Exhibitions
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.categories.group }"
        @click="$store.state.categories.group = !$store.state.categories.group"
      >
        Selected Group Exhibitions
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.categories.performance }"
        @click="$store.state.categories.performance = !$store.state.categories.performance"
      >
        Performances
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.categories.writing }"
        @click="
          $store.state.categories.writing = !$store.state.categories.writing
        "
      >
        Writing
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.categories.related }"
        @click="
          $store.state.categories.related = !$store.state.categories.related
        "
      >
        Texts
      </div>
    </div>
    <div class="settings-group" id="info" v-if="$route.name == 'info'">
      <div class="settings-title">information</div>
      <div
        class="settings-item"
        :class="{ active: $store.state.info.bio }"
        @click="$store.state.info.bio = !$store.state.info.bio"
      >
        Biography
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.info.contact }"
        @click="$store.state.info.contact = !$store.state.info.contact"
      >
        Contact
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.info.newsletter }"
        @click="$store.state.info.newsletter = !$store.state.info.newsletter"
      >
        Newsletter
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.info.news }"
        @click="$store.state.info.news = !$store.state.info.news"
      >
        News
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.info.press }"
        @click="$store.state.info.press = !$store.state.info.press"
      >
        Press
      </div>
      <div
        class="settings-item"
        :class="{ active: $store.state.info.impressum }"
        @click="$store.state.info.impressum = !$store.state.info.impressum"
      >
        Impressum
      </div>
    </div>
    <div class="settings-group" id="cv" v-if="$route.name == 'info'">
      <div class="settings-title">CV</div>
      <div
        v-if="checkCV('solo exhibitions')"
        class="settings-item"
        :class="{ active: $store.state.info.solo_exhibitions }"
        @click="
          $store.state.info.solo_exhibitions = !$store.state.info
            .solo_exhibitions
        "
      >
        Selected Exhibitions
      </div>
      <div
        v-if="checkCV('performances')"
        class="settings-item"
        :class="{ active: $store.state.info.performances }"
        @click="
          $store.state.info.performances = !$store.state.info.performances
        "
      >
        Performances
      </div>
      <div
        v-if="checkCV('filmography')"
        class="settings-item"
        :class="{ active: $store.state.info.filmography }"
        @click="$store.state.info.filmography = !$store.state.info.filmography"
      >
        Filmography
      </div>
      <div
        v-if="checkCV('group exhibitions')"
        class="settings-item"
        :class="{ active: $store.state.info.group_exhibitions }"
        @click="
          $store.state.info.group_exhibitions = !$store.state.info
            .group_exhibitions
        "
      >
        Selected Group Exhibitions
      </div>
      <div
        v-if="checkCV('other')"
        class="settings-item"
        :class="{ active: $store.state.info.other }"
        @click="$store.state.info.other = !$store.state.info.other"
      >
        Other
      </div>
      <div
        v-if="checkCV('writing')"
        class="settings-item"
        :class="{ active: $store.state.info.writing }"
        @click="$store.state.info.writing = !$store.state.info.writing"
      >
        Writing
      </div>
      <div
        v-if="checkCV('collectives')"
        class="settings-item"
        :class="{ active: $store.state.info.collectives }"
        @click="$store.state.info.collectives = !$store.state.info.collectives"
      >
        Collectives
      </div>
      <div
        v-if="checkCV('residencies')"
        class="settings-item"
        :class="{ active: $store.state.info.residencies }"
        @click="$store.state.info.residencies = !$store.state.info.residencies"
      >
        Residencies
      </div>
      <div
        v-if="checkCV('awards')"
        class="settings-item"
        :class="{ active: $store.state.info.awards }"
        @click="$store.state.info.awards = !$store.state.info.awards"
      >
        Awards
      </div>
      <div
        v-if="checkCV('teaching')"
        class="settings-item"
        :class="{ active: $store.state.info.teaching }"
        @click="$store.state.info.teaching = !$store.state.info.teaching"
      >
        Teaching
      </div>
      <div
        v-if="checkCV('bibliography')"
        class="settings-item"
        :class="{ active: $store.state.info.bibliography }"
        @click="
          $store.state.info.bibliography = !$store.state.info.bibliography
        "
      >
        Bibliography
      </div>
    </div>
    <div class="settings-close" @click="$store.state.settings = false">
      CLOSE
    </div>
  </div>
</template>

<script>
import { groupBy } from "lodash";
export default {
  data() {
    return {
      email: "",
    };
  },
  computed: {
    settings() {
      return this.$store.state.settings;
    },
    about() {
      return this.$store.state.pages;
    },
    cv() {
      let groupCv = groupBy(this.about.cv, (project) => project.type);
      return groupCv;
    },
  },
  mounted() {
    this.updateSize();
    this.$root.$on("window-resized", this.updateSize);
  },
  updated() {
    this.updateSize();
  },
  methods: {
    checkCV(type) {
      let check = false;
      for (var cat in this.cv) {
        if (cat == type) {
          check = true;
        }
      }
      return check;
    },
    changeSort(sort) {
      this.$store.state.sort = sort;
    },
    updateSize() {
      this.$store.state.settingsHeight = this.$refs.settings.clientHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  width: 100%;
  box-sizing: border-box;
  background: #ccc;
  display: grid;
  position: fixed;
  top: 0;
  transform: translate3d(0, 0%, 0);
  left: 0;
  z-index: 0;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: var(--lh) calc(4 * var(--lh));
  padding: calc(2 * var(--lh));
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--lh) calc(2 * var(--lh));
  }
  @media only screen and (max-width: 768px) and (orientation: portrait) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--lh);
  }
  .settings-close {
    font-feature-settings: "c2sc", "smcp";
    font-variant: all-small-caps;
    text-transform: lowercase;
    text-align: right;
    grid-column: 5 / 6;
    grid-row: 1 / 2;
    cursor: pointer;
    @media only screen and (max-width: 1024px) {
      grid-column: 4 / 5;
    }
    @media only screen and (max-width: 768px) {
      grid-column: 3 / 4;
    }
  }
  .settings-group {
    grid-column: span 1;
    grid-row: span 1;
    box-sizing: border-box;
    &#cv {
      @media only screen and (max-width: 768px) {
        grid-column: 2 / 4;
        grid-row: 1 / 2;
      }
    }
    &#works {
      @media only screen and (max-width: 768px) {
        grid-column: span 3;
      }
    }
    .settings-title {
      font-variant: small-caps;
      text-transform: lowercase;
      margin-bottom: calc(var(--lh) / 4);
    }
    .settings-form {
      input {
        width: 100%;
        background: transparent;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #aaa;
        outline: none;
        font-family: "us-regular", Times, serif;
        font-size: var(--fs);
        line-height: var(--lh);
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
      }
    }
    .settings-item {
      cursor: pointer;
      transition: color 0.5s;
      position: relative;
      color: #aaa;
      user-select: none;
      padding-left: calc(0.7 * var(--lh));
      &:before {
        content: "";
        position: absolute;
        width: calc(0.35 * var(--lh));
        height: calc(0.35 * var(--lh));
        box-shadow: inset 0 0 0 calc(0.05 * var(--lh)) #aaa;
        left: 0;
        top: calc(var(--lh) / 3);
        transform: translate3d(0, 0, 0) rotate(45deg);
        // transform-origin:;
        transition: box-shadow 0.5s, background 0.5s, transform 0.5s;
      }
      &:hover {
        color: #000;
        &:before {
          transform: translate3d(0, 0, 0) rotate(0deg);
          box-shadow: inset 0 0 0 calc(0.05 * var(--lh)) #000;
        }
      }
      &.active {
        color: #000;
        &:before {
          background: #000;
          transform: translate3d(0, 0, 0) rotate(0deg);
          box-shadow: inset 0 0 0 calc(0.05 * var(--lh)) #000;
        }
        &:hover {
          &:before {
            transform: translate3d(0, 0, 0) rotate(45deg);
          }
        }
      }
    }
  }
}
</style>
